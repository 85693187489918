import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"


const ThankYou = () => {

    return (
        <Layout>
            <Metadata title="Thank You" description="Thank you Page - Coaster Experts" />
            <p>Thank you for your submission! We will reach out shortly!</p>
        </Layout>
    )
}

export default ThankYou
